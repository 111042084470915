import React, { useContext, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeContextDarkmode } from '../contexts/theme';
import { setGlobalState, useGlobalState } from '../';

const Homepage = () => {
  const { loginWithRedirect, isAuthenticated, logout, isLoading, user } = useAuth0();
  const [{ theme, isDark }, toggleTheme] = useContext(ThemeContextDarkmode)
  const loggedin = useGlobalState("loggedin")[0]
  return (
    <Container>
      <Row>
        <Col><h3>Brunnen Verwaltung</h3>
        </Col>
      </Row>
      {!!(isAuthenticated & loggedin) ?
        <>
          <Row>
            <Col><Link to="/wells" className={isDark ? "link-light" : "link-dark"}>Brunnenliste</Link>
            </Col>
          </Row>
          <Row>
            <Col><Link to="/scan" className={isDark ? "link-light" : "link-dark"}>QR code einscannen</Link>
            </Col>
          </Row>
          <Row>
            <Col><Link to="/settings" className={isDark ? "link-light" : "link-dark"}>Benutzereinstellungen</Link>
            </Col>
          </Row>
        </>
        : <Button variant="info" onClick={() => loginWithRedirect()}>Login</Button>
      }
    </Container>
  );
}

export default Homepage;