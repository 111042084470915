import React, { useRef, useState } from 'react';
import { useWells, useAddAction, useActions, useAddSensorAction } from '../hooks/useWells'
import { Spinner } from 'react-bootstrap'

import { useParams } from "react-router";
const Graph = () => {
  const [divWidth, setdivWidth] = useState(300);
  const { wid, sid } = useParams();
  const { data } = useWells()


  //get results from main json
  const data_results = data?.results.length ? data?.results : [];

  // filter current well id
  const data_results_filter = data_results?.filter(data_result => data_result.id === wid.toString());

  // should be only one left in the array
  const well = data_results_filter[0];


  // filter current well id
  const well_filter = well?.sensor?.filter(sensor => sensor.id === sid.toString());
  // should be only one left in the array
  const sensor = well_filter?.length > 0 && well_filter[0];

  const ref = useRef(null)
  React.useEffect(() => {
    //console.log("width", ref.current.offsetWidth);
    setdivWidth(ref.current.offsetWidth)
  }, []);


  const imgurl = "https://sys.geoumweltteam.de/grafana-dev/render/d-solo/zhULG22nk/react?orgId=1&from=now-6M&to=now&height=200&tz=Europe%2FBerlin&var-Brunnen="
  const [Pictures, setPictures] = useState([
    { url: imgurl + sensor.name + "&width=" + divWidth + "&panelId=2", panelId: 2, loadingstate: false, },
    { url: imgurl + sensor.name + "&width=" + divWidth + "&panelId=3", panelId: 3, loadingstate: false, },
    { url: imgurl + sensor.name + "&width=" + divWidth + "&panelId=4", panelId: 4, loadingstate: false, },
  ]);

  const imageOnLoadHandler = (e, panelId) => {
    //console.log("image loaded", e.currentTarget.src)
    let temp = [...Pictures]
    //console.log("temp", temp)
    //console.log("found", temp.find(v => v.panelId === panelId))
    temp.find(v => v.panelId === panelId).loadingstate = true
    setPictures(temp)
  }



  const url = "https://sys.geoumweltteam.de/grafana-dev/d-solo/zhULG22nk/react?orgId=1&from=now-6M&to=now&var-Brunnen="
  //  const url2 = {`https://sys.geoumweltteam.de/grafana-dev/d-solo/ZDgM-C2Wk/brunnen-mit-nummern?orgId=1&from=now-6M&to=now&var-Brunnen=${sensor.name}&panelId=6`}
  return (
    <>
      <h2 ref={ref}>{sensor.name}</h2>



      {sensor.name ?
        <>
          {!Pictures[0].loadingstate &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
          <p style={{visibility: Pictures[0].loadingstate ? "visible" : "hidden"}}><img src={imgurl + sensor.name + "&width=" + divWidth + "&panelId=2"} onLoad={(e) => { imageOnLoadHandler(e, 2) }} /></p>
          {!Pictures[1].loadingstate &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
          <p style={{visibility: Pictures[1].loadingstate ? "visible" : "hidden"}}><img src={imgurl + sensor.name + "&width=" + divWidth + "&panelId=3"} onLoad={(e) => { imageOnLoadHandler(e, 3) }} /></p>
          {!Pictures[2].loadingstate &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
          <p style={{visibility: Pictures[2].loadingstate ? "visible" : "hidden"}}><img src={imgurl + sensor.name + "&width=" + divWidth + "&panelId=4"} onLoad={(e) => { imageOnLoadHandler(e, 4) }} /></p>
        </>
        :

        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {/*     <iframe src={url+sensor.name+"&panelId=2"}
      width={"100%"} height={"200"} frameBorder={"0"}>

    </iframe>
      <iframe src={url+sensor.name+"&panelId=3"}
        width={"100%"} height={"200"} frameBorder={"0"}>

      </iframe>
      <iframe src={url+sensor.name+"&panelId=4"}
        width={"100%"} height={"200"} frameBorder={"0"}>

      </iframe> */}
    </>
  )
}

export default Graph;