
import React, { useContext, useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, ListGroup, Spinner, Button, Modal } from 'react-bootstrap'
import { useWells } from '../hooks/useWells'
import { ThemeContextDarkmode } from '../contexts/theme';
//import { PrintPagination, PrintPaginationContext } from './PrintPagination';
import PrintPagination from '../components/Pagination';
//import Well from './Well';
import {  setGlobalState, useGlobalState } from '../';

//https://developers.google.com/maps/documentation/maps-static/start

let PageSize = 100;

const Wells = () => {
  
  const filterWerkGlobal = useGlobalState("filter_werk")[0]
  const [filterWerk, setFilterWerk] = useState(filterWerkGlobal);
  const { isLoading, isError, data, error } = useWells()

  const [{ isDark }] = useContext(ThemeContextDarkmode)

  // const [{ page, setPage }] = useContext(PrintPaginationContext)

  const currentPageGlobal = useGlobalState("wells_page")[0]
  const [currentPage, setCurrentPage] = useState(currentPageGlobal);

  const unique = [...new Set(data?.results.map(item => item.werk_name))];

  //get results from main json
  const data_results = data?.results.length ? data?.results : [];

  // filter current well id
  const data_results_filter = filterWerk ? data_results?.filter(data_result => data_result.werk_name === filterWerk) : data_results;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data_results_filter?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, isLoading, filterWerk]);

  React.useEffect(() => {
    setGlobalState("wells_page", currentPage)
  },[currentPage])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event) => {
    setFilterWerk(event.target.value)
    setGlobalState("filter_werk", event.target.value)
    setGlobalState("wells_page", 1)
    setCurrentPage(1)
    //console.log("handleinputchange", event.target.value)
  }
  return (
    <Container>
      <Row>
        <Col  className="d-flex align-items-end align-items-center">
          <h2 className={isDark ? "dark" : "light"}>Brunnen</h2>
          <Button variant="primary" onClick={handleShow} size="sm" variant="secondary" style={{ marginLeft: 10 }}>
            Filter{ filterWerk && ": "+filterWerk}
          </Button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>Werk / Standort
              <select className="form-control" name="city" onChange={handleInputChange}>
                {unique?.map((werk) => {
                  return (
                    <option key={werk} value={werk} selected={werk === filterWerk}>{werk}</option>
                  )
                })}
              </select>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div>
            {isLoading && (
              <Spinner animation="border" role="status" > <span className="visually-hidden">Loading...</span></Spinner>
            )}

            {isError && (
              <div>Error fetching data {error.message}</div>
            )}

            {!isLoading && (
              <>
                <PrintPagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={data_results_filter.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
                <ListGroup>
                  {currentTableData?.map((well) => {
                    return (
                      <Link to={`/well/${well.id}`} key={"link" + well.id} className={isDark ? "link-dark" : "link-light"} style={{ textDecoration: 'none' }}>
                        <ListGroup.Item action key={well.id} variant={isDark ? "dark" : "light"}>
                          <h6>{well.messstelle} ({well.werk_name})</h6>
                          {well.location && <img alt={well.messstelle} src={"https://maps.googleapis.com/maps/api/staticmap?center=" + well?.location?.replace("(", "").replace(")", "") + "&zoom=15&size=50x50&maptype=satellite&markers=color:red%7Clabel:S%7C" + well?.location?.replace("(", "").replace(")", "") + "&key=AIzaSyBB00Rm1OtmuLRfNGwRuNwiZXW-GUPLGAk"} />}
                          {well.sensor && <> {well.sensor.length} Logger </>}

                        </ListGroup.Item>
                      </Link>
                    )
                  })}


                </ListGroup>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>

  );
}

export default Wells;