import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Table, Form, ToggleButtonGroup, ToggleButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from "react-router";
import { Link } from 'react-router-dom'

import { useWells, useAddAction, useActions, useAddSensorAction } from '../hooks/useWells'

import { ThemeContextDarkmode } from '../contexts/theme';
import { setGlobalState, useGlobalState } from '../';



const buttonstatus = [];

const Sensor = () => {
  const [{ theme, isDark }, toggleTheme] = useContext(ThemeContextDarkmode)
  const [radioValue, setRadioValue] = useState();

  const { wid, sid } = useParams();
  const loggedin_username = useGlobalState("username")[0]


  const { data } = useWells()
  const { data: dataActions } = useActions()

  //get results from main json
  const data_results = data?.results.length ? data?.results : [];

  // filter current well id
  const data_results_filter = data_results?.filter(data_result => data_result.id === wid.toString());

  // should be only one left in the array
  const well = data_results_filter[0];


  // filter current well id
  const well_filter = well?.sensor?.filter(sensor => sensor.id === sid.toString());
  // should be only one left in the array
  const sensor = well_filter?.length > 0 && well_filter[0];

  // console.log("datares", data_results)
  // console.log("well id:", wid)
  // console.log("well", well)
  // console.log("sensor", sensor)

  const radios = dataActions?.results.map(item => {
    return { id: item.id, name: item.name, value: item.code };
  });
  const { mutate: AddActions, data: AddSensorActionsData } = useAddSensorAction()



  const handleSaveActionsClick = () => {

    //console.log({ action1, action2 })
    //const actions = { id, "actions": { action1, action2 } }
    //console.log("save", radioValue, loggedin_username)
    //const actions = { sid, loggedin_username, "actions": buttonstatus.filter(status => status[3] === true).map(x => x[0]) }
    const actions = { sid, loggedin_username, "actions": radioValue }
    AddActions(actions)
    //setLastAction(AddActionsData)
    //console.log(AddActionsData)
    //console.log(actions)

    // rest the buttons
    setRadioValue([])
  }
  const handleToggleButtonGroup = (e) => {
    setRadioValue(e)
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled"> ID: {sensor?.id}</Tooltip>}>
              <span className="d-inline-block">
                <h5>{sensor?.name}</h5>
              </span>
            </OverlayTrigger>
            <Link to={`/well/${wid}/sensor/${sid}/graph`} key={"link" + wid} className={isDark ? "d-grid gap-2 link-light" : "d-grid gap-2 link-dark"} style={{ textDecoration: 'none' }}>
            <Button variant="success" size="sm">
             Graph
            </Button>

          </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            Brunnen: <Link to={`/well/${well?.id}`} key={"link-well-" + well?.id} className={isDark ? "link-light" : "link-dark"}>{well?.messstelle}</Link>
          </Col>
        </Row>
        <Row>
          <Col>{sensor.manufacturer && <div>Hersteller: {sensor.manufacturer}</div>}</Col>
        </Row>
        <Row>
          <Col>{sensor.manufacturer_serial && <div>SN: {sensor.manufacturer_serial}</div>}</Col>
        </Row>
        <Row>
          <Col>
            <div key="last_data_dt">Letzte Auslesung:</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div key="last_data_dt">Daten bis: {sensor.max && sensor.max}</div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Form>
              <div key="actions" className="d-grid gap-2">
                <ToggleButtonGroup type="checkbox" className="mb-2" vertical={true} onChange={(e) => handleToggleButtonGroup(e)} value={radioValue}>

                  {radios?.map((radio, idx) => (

                    <ToggleButton
                      key={idx}
                      id={`radio-${radio.id}`}
                      type="radio"
                      variant={isDark ? "outline-light" : "outline-dark"}
                      name={`radioname-${radio.id}`}
                      value={radio.value}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Button variant="danger" onClick={handleSaveActionsClick}>Speichern</Button>
              </div>
            </Form>

          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mt-3">
              <Table striped bordered hover variant={isDark ? "dark" : "light"}>
                <thead>
                  <tr>
                    <th>Aktion</th>
                    <th>Alter</th>
                    <th>Datum</th>
                    <th>Person</th>
                  </tr>
                </thead>
                <tbody>{sensor?.log?.length > 0 && sensor?.log?.map((logs) => {
                  return (
                    <tr key={logs.id}>
                      <td>{logs.name}</td>
                      <td>{logs.age}</td>
                      <td>{logs.action_dt}</td>
                      <td>{logs.username}</td>
                    </tr>
                  )
                })}</tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sensor;