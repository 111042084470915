import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';
import { Pagination } from 'react-bootstrap'

//https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/


const PrintPagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  
  let lastPage = paginationRange[paginationRange?.length - 1];
  return (

    <Pagination size="sm">
      {/* Left navigation arrow */}
      <Pagination.Prev onClick={onPrevious} disabled={currentPage === 1} />

      {paginationRange.map(pageNumber => {

        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        // Render our Page Pills
        return (

          <Pagination.Item key={pageNumber} active={pageNumber === currentPage} onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </Pagination.Item>

        );
      })}
      {/*  Right Navigation arrow */}
      <Pagination.Next onClick={onNext} disabled={currentPage === lastPage} />

    </Pagination>
  );
};

export default PrintPagination;