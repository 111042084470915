import React, { useContext, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Link, useLocation, Navigate } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
//import Navbar from './components/Navbar';

import { Nav, Navbar, Button, Spinner } from 'react-bootstrap'
//import { LinkContainer } from './tests/LinkContainer'
import LinkContainer from './tests/LinkContainer'

import Wells from './components/Wells';
import Well from './components/Well';
//import Signin from './components/Signin';
import Homepage from './components/Homepage';
import Settings from './components/Settings';
//import CameraPage from './components/Camera';
import QRcode from './components/QRcode';
import ScanRFID from './components/ScanRFID';
import Sensor from './components/Sensor';
import Graph from './components/Graph';
import Location from './components/Location';

//https://levelup.gitconnected.com/dark-mode-in-react-533faaee3c6e
//import { ThemeContext, themes } from './contexts/ThemeContext';
//import ToggleDark from './components/ToggleDark';
import { ThemeContextDarkmode } from './contexts/theme';

import Container from 'react-bootstrap/Container';

import { useAuth0 } from '@auth0/auth0-react'

import './App.css';

import { useSigninAuth0 } from './hooks/useWells'

//Guide0: https://www.youtube.com/watch?v=w7ejDZ8SWv8 (crashcourse)
//Guide1: https://www.youtube.com/watch?v=MKb7FSt74f8 (signinform)
//Guide2: https://www.youtube.com/watch?v=fQ4aaG02l70
//Guide3: https://www.youtube.com/playlist?list=PLC3y8-rFHvwjTELCrPrcZlo6blLBUspd2


import { setGlobalState, useGlobalState } from '.';

function App() {
  const { loginWithRedirect, isAuthenticated, logout, isLoading, user } = useAuth0();
  const { data, refetch } = useSigninAuth0(user?.sub);

  const loggedin = useGlobalState("loggedin")[0]
  const loggedin_username = useGlobalState("username")[0]

  React.useEffect(() => {
    (isAuthenticated & !data) && refetch()
    //console.log("auth0login", data)
    //console.log("loggedin", loggedin)
    //console.log("loggedin", loggedin_username)
  }, [isAuthenticated, data]);

  //let [page, setPage] = useState('wells');
  //const [darkMode, setDarkMode] = React.useState(false);
  //ToggleDark()
  const [{ theme, isDark }, toggleTheme] = useContext(ThemeContextDarkmode)

  

  return (
    <Router>
        <Container>
          <Navbar expand="sm" bg="light" variant="light" collapseOnSelect>
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer to="/">
                    <Nav.Link className="home">Home</Nav.Link>
                  </LinkContainer>
                  {!!(isAuthenticated & loggedin) &&
                    <>
                      <LinkContainer to="/wells">
                        <Nav.Link>Brunnen</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/scan">
                        <Nav.Link>Scan</Nav.Link>
                      </LinkContainer>
                    </>
                  }
                  {isAuthenticated &&
                    <LinkContainer to="/settings">
                      <Nav.Link>Settings</Nav.Link>
                    </LinkContainer>
                  }
                  { loggedin_username === "weisc" && 
                    <LinkContainer to="/scanrfid">
                      <Nav.Link>Scan RFID</Nav.Link>
                    </LinkContainer>
                  }
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  {/* {loggedin ? <>
                    Signed in as:&nbsp;
                    <Link to="/settings" className="link-light">{loggedin_username}</Link>&nbsp;
                    <Button variant="warning" onClick={(e) => setGlobalState("loggedin", false)}>Logout</Button>
                    &nbsp;</>
                    : <>
                      <Link to="/signin"><Button variant="info">login</Button></Link>
                    </>}&nbsp; */}
                    { (!isLoading && isAuthenticated) && <Button variant="info" onClick={() => logout({returnTo: window.location.origin})}>Logout</Button> }
                    { (!isLoading && !isAuthenticated) && <Button variant="info" onClick={() => loginWithRedirect()}>Login</Button> }
                    { isLoading && <Spinner animation="border" role="status" size="sm" > <span className="visually-hidden">Loading...</span></Spinner>}
                   
                    
                  { loggedin_username === "weisc" && <Button onClick={toggleTheme}>{isDark ? <>Hell</> : <>Dunkel</>}</Button> }
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
        <Container className="mt-2">
          <Routes>
            {isAuthenticated &&
              <Route path='/wells' element={<Wells />} />
            }
            {isAuthenticated &&
              <Route path='/well/:wid' element={<Well />} />
            }
            {isAuthenticated &&
              <Route path='/well/:wid/sensor/:sid' element={<Sensor />} />
            }
            {isAuthenticated &&
              <Route path='/well/:wid/sensor/:sid/graph' element={<Graph />} />
            }
            {isAuthenticated &&
              <Route path='/well/:wid/location' element={<Location />} />
            }
            {isAuthenticated &&
              <Route path='/settings' element={<Settings />} />
            }
            {/* <ProtectedRoute path='/settings' element={<Settings />} /> */}
            {isAuthenticated &&
              <Route path='/scan' element={<QRcode />} />
            }
            { loggedin_username === "weisc" && 
              <Route path='/scanrfid' element={<ScanRFID />} />
            }
            <Route path='/' element={<Homepage />} />
            <Route path='*' element={<Homepage />} />
          </Routes>
        </Container>
    </Router>
  );
}

export default App;
