import { createContext, useState, useEffect } from "react";

const themes = {
    dark: {
        backgroundColor: "black",
        color: "white",
    },
    light: {
        backgroundColor: "white",
        color: "black",
    }
 }

export const ThemeContextDarkmode = createContext();

export const ThemeProviderDarkmode = ({children}) => {
    const [isDark, setIsDark] = useState(false)
    const theme  = isDark ? themes.dark : themes.light;
    const setTheme = (props) => {

        //console.log("Theme: setTheme", props)
        console.log("setTheme", props.target.checked)
        localStorage.setItem("isDark", JSON.stringify(props.target.checked));
        setIsDark(props.target.checked);
    }
    const toggleTheme = () => {
        localStorage.setItem("isDark", JSON.stringify(!isDark));
        setIsDark(!isDark);
    }
    useEffect(() => {
        const isDark = localStorage.getItem("isDark") === "true"
        setIsDark(isDark);
    }, [] )

    useEffect(() => {
        let temp = ""
        switch (theme) {
          case themes.light:
            document.body.classList.add('white-content');
            temp = document.getElementsByTagName("table")
            for (let table of temp) {
               table.classList.remove('table-dark');
            }
            temp = document.getElementsByTagName("input")
            for (let table of temp) {
              table.classList.remove('bg-dark');
              table.classList.remove('text-light');
            }
            temp = document.getElementsByTagName("nav")
            for (let table of temp) {
              table.classList.replace('bg-dark', 'bg-light');
              table.classList.replace('navbar-dark', 'navbar-light');
            }
            temp = document.getElementsByClassName("btn")
            for (let table of temp) {
              table.classList.replace('btn-outline-dark', 'btn-outline-primary');
            }
    
            temp = document.getElementsByTagName("button")
            for (let table of temp) {
              table.classList.replace('list-group-item-dark','list-group-item-light');
            }
            //
            break;
          case themes.dark:
          default:
            document.body.classList.remove('white-content');
            temp = document.getElementsByTagName("table")
            for (let table of temp) {
               table.classList.add('table-dark');
            }
            temp = document.getElementsByTagName("input")
            for (let table of temp) {
              table.classList.add('bg-dark');
              table.classList.add('text-light');
            }
            temp = document.getElementsByTagName("nav")
            for (let table of temp) {
              table.classList.replace('bg-light', 'bg-dark');
              table.classList.replace('navbar-light', 'navbar-dark');
            }
            temp = document.getElementsByClassName("btn")
            for (let table of temp) {
              table.classList.replace('btn-outline-primary','btn-outline-dark');
            }  
            temp = document.getElementsByTagName("button")
            for (let table of temp) {
              table.classList.replace('list-group-item-light','list-group-item-dark');
            }
            break;
        }
      }, [theme]);

    return (
        <ThemeContextDarkmode.Provider value={[{theme, isDark}, toggleTheme, setTheme]}>{children}</ThemeContextDarkmode.Provider>
    )
}