import React, { useState, useContext, useRef } from 'react';
import { useParams } from "react-router";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import Map from '../components/Map'
import { useAddWellLocation, useWells } from '../hooks/useWells'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useGlobalState } from '../';
import { ThemeContextDarkmode } from '../contexts/theme';
const mapOptions = {
  mapTypeId: "satellite",
  zoomControlOptions: {
    //position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
    // ...otherOptions
  }
}
const libraries = ['places']
const Location = () => {
  const [{ isDark }] = useContext(ThemeContextDarkmode)
  const { wid } = useParams();
  const [show, setShow] = useState(false);
  const loggedin_username = useGlobalState("username")[0]
  const { mutate: AddWellLocation, data: AddWellLocationData } = useAddWellLocation()

  const { data } = useWells()

  //get results from main json
  const data_results = data?.results.length ? data?.results : [];

  // filter current well id
  const data_results_filter = data_results?.filter(data_result => data_result.id === wid?.toString());

  // should be only one left in the array
  const well = data_results_filter[0];

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const mapRef = useRef(null);

  const loc = { lat: lat, lng: lng }

  // on first load
  React.useEffect(() => {
    getLocation()
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        //console.log("getlocation", lat, lng)
      }, () => {
        setStatus('Unable to retrieve your location');
      });
    }
  }


  return (
    <Container>
      <Row>
        <Col><h2>Position aktualisieren</h2>
        </Col>
      </Row>
      <Row>
        <Col>Brunnen: <strong>{well?.messstelle}</strong>
        </Col>
        <Col>Latitude: {lat ? <strong>{lat}</strong> : <Spinner animation="border" size="sm" />}
        </Col>
      </Row>
      <Row>
        <Col>Wasserwerk: <strong>{well?.werk_name}</strong>
        </Col>
        <Col>Longitude: {lng ? <strong>{lng}</strong> : <Spinner animation="border" size="sm" />}
        </Col>
      </Row>
      <Row>
      </Row>
      <Row>
      </Row>

      <Row>
        <Col>

          <div className="App mt-3">
            <p>{status}</p>
            {lat &&
              <LoadScript googleMapsApiKey="AIzaSyBB00Rm1OtmuLRfNGwRuNwiZXW-GUPLGAk" libraries={libraries}>
                <Map
                  mapContainerStyle={{
                    width: '100%',
                    height: '400px'
                  }}
                  center={loc}
                  zoom={17}
                  tilt={0}
                  options={mapOptions}
                  addToWell={wid}
                  refresh={true}
                >
                  { /* Child components, such as markers, info windows, etc. */}
                  <></>
                </Map>
              </LoadScript>

            }
            <Link to={`/well/${wid}`} key={"link" + wid} className={isDark ? "link-light" : "link-dark"} style={{ textDecoration: 'none' }}>
              <Button variant="secondary">Abbrechen</Button>
            </Link>
          </div>



        </Col>
      </Row>

    </Container>
  );
}


export default Location;