import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { createGlobalState } from 'react-hooks-global-state';
import { ThemeProviderDarkmode } from './contexts/theme';
import { Auth0Provider } from '@auth0/auth0-react';
const { setGlobalState, useGlobalState } = createGlobalState({ 
  count: 0,
  token: "",
  loggedin: false,
  username: "",
  filter_werk: "",
  wells_page: 1,
});

export { useGlobalState, setGlobalState };


const queryClient = new QueryClient();


ReactDOM.render(
    <ThemeProviderDarkmode>
      <Auth0Provider domain="dev-e69wxrvs.eu.auth0.com" clientId="bWgtLDfyW1MS9um262rMKLnVmVvz08Re" redirectUri={window.location.origin}>
        
      <QueryClientProvider client={queryClient} contextSharing={true}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      </Auth0Provider>
    </ThemeProviderDarkmode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
