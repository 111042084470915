import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import { Row, Col, Container, Form } from 'react-bootstrap';
import { ThemeContextDarkmode } from '../contexts/theme';
import { useGlobalState } from '../';

const Settings = () => {
  //const { user, isAuthenticated, getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
  const { user, isAuthenticated } = useAuth0();
  //console.log("auth", user)
  const [{ isDark }, setTheme] = React.useContext(ThemeContextDarkmode)


  const loggedin = useGlobalState("loggedin")[0]
  const loggedin_username = useGlobalState("username")[0]
 /*  React.useEffect(() => {
    const getUserRoles = async () => {
      const domain = "dev-e69wxrvs.eu.auth0.com";
      let accessToken = ""
      try {
        accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        })
      } catch (error) {
        console.log("error 1", error);
        try {
          accessToken = await getAccessTokenWithPopup({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          });
        } catch (error) {
          console.log("2nd", error)
          return false
        }
      }

      console.log("error", accessToken);
              //  const accessToken = await getAccessTokenWithPopup({
              //   audience: `https://${domain}/api/v2/`,
              //   scope: "read:current_user",
              // }); 

      const userRolesUrl = `https://${domain}/api/v2/users/USER_ID/roles`
      const userDetailsByIdUrl = `https://${domain}/userinfo`;

      axios.get(userRolesUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          //setLSValue(LS_KEYS_PRIVATE.USER_TYPE, response.data["https://app-brunnen.geoumweltteam.de/roles"][0]); // this is to set a localstorage
          console.log("user info resp", response)
        })
        .catch(error => {
          console.log('ERROR API RESPONSE => ', error)
        });


    };

    getUserRoles();
  }, [getAccessTokenSilently]); */

  return (
    <>
      {isAuthenticated &&
        <>
          <Container>
            <Row className="justify-content-md-center">
              <h3>Settings</h3>
            </Row>
            <Row className="justify-content-md-center">
              <Col><img src={user.picture} alt={user.name} className="rounded-circle img-fluid profile-picture mb-3 mb-md-0" /></Col>
            </Row>
            <Row>
              <Col>User</Col>
              <Col>{user.name}</Col>
            </Row>
            <Row>
              <Col>Email</Col>
              <Col>{user.email}</Col>
            </Row>
            <Row>
              <Col>Sprache</Col>
              <Col>{user.locale}</Col>
            </Row>
            <Row>
              <Col>Loggedin</Col>
              <Col>{loggedin}</Col>
            </Row>
            <Row>
              <Col>Username</Col>
              <Col>{loggedin_username}</Col>
            </Row>
            <Row className="mt-4">
              <Col><Form.Check
                type="switch" checked={isDark && <>checked</>}
                id="custom-switch"
                label="Dark mode" onChange={setTheme}
              /></Col>
            </Row>{
              <Row>
                {/* <Col>{JSON.stringify(user, null, 2)}</Col> */}
              </Row>}
          </Container>

        </>
      }
    </>
  );
}

export default Settings;


