
import React from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin.js'
import ResultContainerPlugin from './ResultContainerPluginv2.js'
import { Container, Row, Col } from 'react-bootstrap'
//import HowToUse from './HowToUse.jsx'

// create QR
// https://keremerkan.net/qr-code-and-2d-code-generator/
// https://qr.io/

// generate UUID
// https://www.guidgenerator.com/online-guid-generator.aspx

class QRcode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      decodedResults: []
    }

    // This binding is necessary to make `this` work in the callback.
    this.onNewScanResult = this.onNewScanResult.bind(this);
  }

  render() {
    return (<Container>
      <Row>
        <Col>
          <div className="App">
            <section className="App-section">
              <div className="App-section-title"></div>
              <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={this.onNewScanResult} />
              <div></div>
              <ResultContainerPlugin results={this.state.decodedResults} />
            </section>
          </div>
        </Col>
      </Row>
    </Container>

    );
  }

  onNewScanResult(decodedText, decodedResult) {
    //console.log(Date().toLocaleString(), "App [result]", decodedResult);



    // let decodedResults = this.state.decodedResults;
    // decodedResults.push(decodedResult);
    this.setState((state, props) => {
      state.decodedResults.push(decodedResult);
      return state;
    });
  }
}

export default QRcode;