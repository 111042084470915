import React, { useState, useContext, useRef } from 'react';
import { useParams } from "react-router";
import { Link } from 'react-router-dom'
import { Container, Row, Col, ListGroup, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { useAddWellLocation, useWells } from '../hooks/useWells'
import { ThemeContextDarkmode } from '../contexts/theme';
import { useGlobalState } from '../';

//https://react-google-maps-api-docs.netlify.app/
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


//console.log(JSON.stringify(Object.fromEntries(params.entries())))

/*
const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

const fetchWells = async (id) => {
  const params = new FormData()
  params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
  params.append('method', 'get')
  //console.log(params.toString())
  //console.log(JSON.stringify(Object.fromEntries(params.entries())))
  //console.log(config)
  const response = await axios.post(`https://sys.geoumweltteam.de/tools/api/brunnen/${id}/`, params);
  return response.data;
}
const addActions = async (actions) => {
  const params = new FormData()
  params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
  params.append('method', 'add')
  params.append('log_action', JSON.stringify(actions.actions))
  params.append('user', 3)
  //console.log(params.toString())
  //console.log(JSON.stringify(Object.fromEntries(params.entries())))
  const id = actions.id
  const response = await axios.post(`https://sys.geoumweltteam.de/tools/api/brunnen/${id}/`, params);
  return response.data;
}


const useAddAction = () => {
  const queryClient = useQueryClient()
  return useMutation(addActions, {
    onSuccess: () => {
      queryClient.invalidateQueries('wells')
    },
    retry: 3,
  })
}

*/

const containerStyle = {
  width: '100%',
  height: '150px'
};
const mapOptions = {
  mapTypeId: "satellite",
  zoomControlOptions: {
    //position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
    // ...otherOptions
  }
}


//const buttonstatus = [];
const Well = () => {
  const [{ isDark }] = useContext(ThemeContextDarkmode)
  const { wid } = useParams();
  //const loggedin_username = useGlobalState("username")[0]
  // const { isLoading, isError, data, error } = useQuery(['wells', id], () => fetchWells(id), {
  //    cacheTime: 300000 // default 5 min
  //  , staleTime: 300000
  //  })
  //const { isLoading, isError, data, error } = useWells()
  const { data } = useWells()

  //get results from main json
  const data_results = data?.results.length ? data?.results : [];

  // filter current well id
  const data_results_filter = data_results?.filter(data_result => data_result.id === wid?.toString());

  // should be only one left in the array
  const well = data_results_filter[0];

  //console.log(data_results)
  //console.log(id)
  //console.log(well)

  const [location, setLocation] = useState({
    lat: 48,
    lng: 11
  });

  //const { isLoading: AddActionsIsLoading, isError: AddActionsIsError, mutate: AddActions, data: AddActionsData, error: AddActionsError } = useMutation(addActions)
  //const { isLoading: AddActionsIsLoading, isError: AddActionsIsError, mutate: AddActions, data: AddActionsData, error: AddActionsError } = useAddAction()
  //const { mutate: AddActions, data: AddActionsData } = useAddAction()

  /*   const handleSaveActionsClick = () => {
  
      //console.log({ action1, action2 })
      //const actions = { id, "actions": { action1, action2 } }
      console.log("save", buttonstatus, loggedin_username)
      const actions = { id, loggedin_username, "actions": buttonstatus.filter(status => status[3] === true).map(x => x[0]) }
      AddActions(actions)
      //setLastAction(AddActionsData)
      //console.log(AddActionsData)
      //console.log(actions)
    } */
  /*   const handleButtonGroup = (props) => {
      buttonstatus.length = 0
  
      Array.from(props.target.parentElement.children).forEach((item) => {
        if (item.tagName === 'INPUT') {
          //console.log(item.value, item.id, item.checked, item.name)
          buttonstatus.push([item.value, parseInt(item.id.replace('radio-', '')), item.id, item.checked])
        }
      })
      console.log("select", buttonstatus)
  
    } */
  React.useEffect(() => {
    if (well?.location) {

      setLocation({
        lat: parseFloat(well?.location.replace("(", "").replace(")", "").split(",")[0]),
        lng: parseFloat(well?.location.replace("(", "").replace(")", "").split(",")[1])
      })
      //console.log("use effect", location)
    }
  }, [well])





  return (
    <Container>
      <Row>
        <Col>
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled"> ID: {well?.id}</Tooltip>}>
            <span className="d-inline-block">
              <h2>{well?.messstelle}</h2>
            </span>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col>
          Wasserwerk: <strong>{well?.werk_name}</strong>
        </Col>
      </Row> 
      <Row>
        <Col>


        </Col>
      </Row>
      {well?.location &&
        <Row className="mt-2">
          <Col className="text-center">
            <LoadScript googleMapsApiKey="AIzaSyBB00Rm1OtmuLRfNGwRuNwiZXW-GUPLGAk">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={17}
                tilt={0}
                options={mapOptions}
              >
                { /* Child components, such as markers, info windows, etc. */}
                <Marker position={location} label={well?.messstelle} />
                <></>
              </GoogleMap>
            </LoadScript>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <Link to={`/well/${wid}/location`} key={"link" + wid} className={isDark ? "d-grid gap-2 link-light" : "d-grid gap-2 link-dark"} style={{ textDecoration: 'none' }}>
            <Button variant="success" size="sm">
              Neue Location setzen
            </Button>

          </Link>

        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Button size="sm" href={"https://www.google.com/maps/dir/?api=1&destination=" + location.lat + "," + location.lng + "&dir_action=navigate&travelmode=walking"}>Navigation starten</Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div key="last_data_dt">Letzte Auslesung: {well?.last_data_dt}</div>
          <div key="last_action">Letzte Aktion: {well?.last_action_dt} ({well?.last_action})</div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col><h5>Sensoren</h5></Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {well?.sensor?.map((sensor) => {
              return (
                <Link to={`/well/${wid}/sensor/${sensor.id}`} key={"link" + sensor.id} className={isDark ? "link-dark" : "link-light"} style={{ textDecoration: 'none' }}>
                  <ListGroup.Item action key={sensor.id} variant={isDark ? "dark" : "light"}>

                    <Row>
                      <Col><h6>{sensor.name}</h6></Col>
                      <Col>{sensor.depth && <div>Tiefe: {sensor.depth}</div>}</Col>
                      <Col style={{ whiteSpace: 'nowrap' }}>{sensor.max && <div>Bis {sensor.max}</div>}</Col>
                      <Col>{sensor.manufacturer && <div>Hersteller: {sensor.manufacturer}</div>}</Col>
                      <Col>{sensor.manufacturer_serial && <div>SN: {sensor.manufacturer_serial}</div>}</Col>
                    </Row>



                  </ListGroup.Item>
                </Link>
              )
            })}


          </ListGroup>
        </Col>
      </Row>
    </Container>

  );
}

export default Well;