//import { render } from '@testing-library/react';
import React from 'react';
import { useWells } from '../hooks/useWells'
import { Link, useNavigate } from 'react-router-dom'

function filterResults(results) {
  //console.log("filterresult", results.length)
  let filteredResults = [];
  for (var i = 0; i < results?.length; ++i) {
    if (i === 0) {
      filteredResults.push(results[i]);
      continue;
    }

    if (results[i].decodedText !== results[i - 1].decodedText) {
      filteredResults.push(results[i]);
    }
  }
  return filteredResults;
}



// const ResultContainerTable = () => {
//   const { isLoading, isError, data, error } = useWells()
//   console.log("ResultContainerTable", "start")
//   var results = filterResults(data?.results);
//   const ShowWell = ({ decodedText }) => {
//     //function ShowWell({ decodedText }) {

//     //const { data } = useWells()
//     console.log("HookWrapperTest", data)
//     //return <div>{decodedText}</div>

//   }
//   React.useEffect(() => {
//     results = filterResults(data?.results);
//   }, [data]); 
//   return (
//     <table className={'Qrcode-result-table'}>
//       <thead>
//         <tr>
//           <td>#</td>
//           <td>Decoded Text</td>
//           <td>Format</td>
//           <td>Brunnen</td>
//         </tr>
//       </thead>
//       <tbody>
//         {
//           results.map((result, i) => {
//             console.log(result);
//             return (<tr key={i}>
//               <td>{i}</td>
//               <td>{result?.decodedText}</td>
//               <td>{result?.result?.format.formatName}</td>
//               <td><div>{!!(result?.decodedText & data.results?.filter(data_result => data_result.id === result?.decodedText).length > 0) && <Link to={`/well/${result?.decodedText}`} id={"link" + result?.decodedText} className="link-light"><div>gefunden</div></Link>} </div>
//               </td>
//             </tr>);
//           })
//         }
//       </tbody>
//     </table>
//   )
// }
// class ResultContainerTable extends React.Component {
//   render() {
//     var results = filterResults(this.props.data);
//     return (
//       <table className={'Qrcode-result-table'}>
//         <thead>
//           <tr>
//             <td>#</td>
//             <td>Decoded Text</td>
//             <td>Format</td>
//             <td>Brunnen</td>
//           </tr>
//         </thead>
//         <tbody>
//           {
//             results.map((result, i) => {
//               console.log(result);
//               return (<tr key={i}>
//                 <td>{i}</td>
//                 <td>{result.decodedText}</td>
//                 <td>{result.result.format.formatName}</td>
//                 <td><ShowWell decodedText={result.decodedText} /></td>
//               </tr>);
//             })
//           }
//         </tbody>
//       </table>
//     );
//   }
// }
const ResultContainerPlugin = (props) => {
  let navigate = useNavigate();
  const { isLoading, isError, data, error } = useWells()

  let results = filterResults(props.results);
  let last=results[results.length-1]; 
  //console.log(results)
  const result = data.results?.find((fwell) => {
    return fwell?.sensor?.some((fsensor) => {
      return fsensor.uuid === last?.decodedText
    })
  })
  //console.log("res", result)
  const sensor = result?.sensor?.filter(sensor => sensor?.uuid == last?.decodedText)
  //console.log("uuid", sensor)
  if (sensor?.length > 0) {
    //console.log("navigate", "/well/" + sensor[0].brunnen_meta_id + "/sensor/" + sensor[0].id)
    navigate("/well/" + sensor[0].brunnen_meta_id + "/sensor/" + sensor[0].id);
  }
/*    if (data.results?.filter(data_result => data_result.id === last?.decodedText).length > 0) {
    console.log("navigate", data.results)  
    //navigate("/well/" + wid);
  }  */
  return (
    <div className='Result-container'>
      <div className='Result-header'>Scanned results ({results.length})</div>
      <div className='Result-section'>
        <table className={'Qrcode-result-table'}>
          <thead>
            <tr>
              <td>#</td>
              <td>Decoded Text</td>
              <td>Format</td>
              <td>Brunnen</td>
            </tr>
          </thead>
          <tbody>
            {
              results.map((result, i) => {
                //console.log("resultmap", result?.decodedText,  data.results?.filter(data_result => data_result.id === result?.decodedText))
                return (<tr key={i}>
                  <td>{i}</td>
                  <td>{result?.decodedText}</td>
                  <td>{result?.result?.format.formatName}</td>
                  <td><div>{data.results?.filter(data_result => data_result.id === result?.decodedText).length > 0 && <Link to={`/well/${result?.decodedText}`} id={"link" + result?.decodedText} className="link-light"><div>gefunden</div></Link>} </div>
                  </td>
                </tr>);
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
// class ResultContainerPlugin extends React.Component {
//     render() {
//         let results = filterResults(this.props.results);
//         return (<div className='Result-container'>
//             <div className='Result-header'>Scanned results ({results.length})</div>
//             <div className='Result-section'>
//                 <ResultContainerTable data={this.props.results} />
//             </div>
//         </div>);
//     }
// }

export default ResultContainerPlugin;