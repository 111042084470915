import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios';
import { setGlobalState } from '..';

const params = new URLSearchParams()
params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

/********************************************************************************************************
 * 
 *  Query 1: Get wells
 * 
 */
const fetchWells = () => {
    return axios.post("https://sys.geoumweltteam.de/tools/api/brunnen/", params, config).then((response) => response.data)

}

//const useWells = (onSuccess, onError) => {
export const useWells = () => {
    return useQuery('wells', fetchWells, {
        cacheTime: 300000 // default 5 min
        , staleTime: 300000
    })
}

/********************************************************************************************************
 * 
 *  Query 2: Login
 * 
 */
const fetchSignin = async (username, password) => {
    console.log("login: " + username)
    const params = new FormData()
    params.append('username', username)
    params.append('password', password)
    const response = await axios.post("https://sys.geoumweltteam.de/tools/api/login/", params, config)
    //console.log(response)
    return response.data;

}

export const useSignin = (username, password) => {
    return useQuery('sign-in', () => fetchSignin(username, password), {
        cacheTime: 0, // default 5 min
        staleTime: 0,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            //console.log("success")
            //console.log(data)
            if (data.login === "ok") {
                setGlobalState("loggedin", true)
                setGlobalState("username", data.username)
            }
        }
    })
}


/********************************************************************************************************
 * 
 *  Query 3: Get actions
 * 
 */
 const fetchActions = () => {
    return axios.post("https://sys.geoumweltteam.de/tools/api/actions/", params, config).then((response) => response.data)

}

export const useActions = () => {
    return useQuery('actions', fetchActions, {
        cacheTime: 300000 // default 5 min
        , staleTime: 300000
    })
}
/********************************************************************************************************
 * 
 *  Query 4: Login with auth0
 * 
 */
 const fetchSigninAuth0 = async (auth0sub) => {
    //console.log("auth0 sub: " + auth0sub)
    const params = new FormData()
    params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
    params.append('auth0sub', auth0sub)
    const response = await axios.post("https://sys.geoumweltteam.de/tools/api/loginauth0/", params, config)
    //console.log(response)
    return response.data;

}

export const useSigninAuth0 = (auth0sub) => {
    return useQuery('sign-in2', () => fetchSigninAuth0(auth0sub), {
        cacheTime: 0, // default 5 min
        staleTime: 0,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            //console.log("success")
            //console.log(data)
            if (data.results.length === 1) {
                setGlobalState("loggedin", true)
                setGlobalState("username", data.results[0].logon)
            }
        }
    })
}

/********************************************************************************************************
 * 
 *  Mutation 1: Add actions brunnen
 * 
 */
 const addActions = async (actions) => {
    const id = actions.id
    const username = actions.loggedin_username
    //console.log(actions)
    //console.log(JSON.stringify(actions.actions))
    //console.log(id)
    const params = new FormData()
    params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
    params.append('method', 'add')
    params.append('log_action', JSON.stringify(actions.actions))
    params.append('id', id)
    params.append('user', username)
    //console.log(params.toString())
    //console.log(JSON.stringify(Object.fromEntries(params.entries())))
    const response = await axios.post(`https://sys.geoumweltteam.de/tools/api/brunnen/${id}/`, params);
    return response.data;
}


export const useAddAction = () => {
    const queryClient = useQueryClient()
    //console.log("useAddAction")
    return useMutation(addActions, {
        onSuccess: () => {
            queryClient.invalidateQueries('wells')
        },
        retry: 3,
    })
}

/********************************************************************************************************
 * 
 *  Mutation 2: Add actions sensor
 * 
 */
const addSensorActions = async (actions) => {
    const id = actions.sid
    const username = actions.loggedin_username
    //console.log(actions)
    //console.log(JSON.stringify(actions.actions))
    //console.log(id)
    const params = new FormData()
    params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
    params.append('method', 'add')
    params.append('log_action', JSON.stringify(actions.actions))
    params.append('id', id)
    params.append('user', username)
    //console.log(params.toString())
    //console.log(JSON.stringify(Object.fromEntries(params.entries())))
    const response = await axios.post(`https://sys.geoumweltteam.de/tools/api/sensor/${id}/`, params);
    return response.data;
}


export const useAddSensorAction = () => {
    const queryClient = useQueryClient()
    //console.log("useAddAction")
    return useMutation(addSensorActions, {
        onSuccess: () => {
            queryClient.invalidateQueries('wells')
        },
        retry: 3,
    })
}


/********************************************************************************************************
 * 
 *  Mutation 3: Add loction to well
 * 
 */
 const addWellLocation = async (location) => {
    const id = location.wid
    const username = location.loggedin_username
    //console.log(actions)
    //console.log(JSON.stringify(actions.actions))
    //console.log(id)
    const params = new FormData()
    params.append('key', 'bgm98b4t8mF094boibserlisbPo983bw09')
    params.append('method', 'add')
    params.append('location', location.location)
    params.append('id', id)
    params.append('user', username)
    //console.log(params.toString())
    //console.log(JSON.stringify(Object.fromEntries(params.entries())))
    const response = await axios.post(`https://sys.geoumweltteam.de/tools/api/location/${id}/`, params);
    return response.data;
}


export const useAddWellLocation = () => {
    const queryClient = useQueryClient()
    //console.log("useAddAction")
    return useMutation(addWellLocation, {
        onSuccess: () => {
            queryClient.invalidateQueries('wells')
        },
        retry: 3,
    })
}