import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, ListGroup, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { LoadScript, GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import { useGlobalState } from '../';
import { useAddWellLocation } from '../hooks/useWells'


// google url api
//https://developers.google.com/maps/documentation/urls/get-started#directions-action

// google maps react api
// https://react-google-maps-api-docs.netlify.app/#googlemap
let varautocomplete = null
export default function Map(props) {
    let navigate = useNavigate();
    const { mutate: AddWellLocation, data: AddWellLocationData } = useAddWellLocation()
    const loggedin_username = useGlobalState("username")[0]
    const mapRef = useRef(null);
    const [position, setPosition] = useState(props.center);
    const [center, setCenter] = useState(props.center);
    const wid = props.addToWell
    const handleAddLocation = (event) => {
        const location = { wid, loggedin_username, "location": position.lat + "," + position.lng }
        AddWellLocation(location)
        //console.log("add location", event)
        console.log("lat,lng", location)
        navigate("/well/" + wid);
    }
    const getLocation = () => {
        if (!navigator.geolocation) {
            //setStatus('Geolocation is not supported by your browser');
        } else {
            //setStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                //setStatus(null);
                //setLat(position.coords.latitude);
                //setLng(position.coords.longitude);

                setPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
                setCenter({ lat: position.coords.latitude, lng: position.coords.longitude }); 
                //console.log("getloc",position.coords.latitude, position.coords.longitude )
            }, () => {
                //setStatus('Unable to retrieve your location');
            });
        }
    }
    function handleLoad(map) {
        mapRef.current = map;
    }

    function handleCenterChanged(wid) {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        setPosition(newPos);
        const location = { wid, loggedin_username, "location": position.lat + "," + position.lng }
        //console.log("lat,lng", location)
    }
    const handleOnLoad = ((autocomplete) => {
        //console.log('autocomplete: ', autocomplete)
    
        varautocomplete = autocomplete
      }
    )
    const handleOnPlaceChanged =(() => {
        //console.log('ahandleutocomplete: ', varautocomplete)
        if (varautocomplete !== null) {
          //console.log(varautocomplete.getPlace())
          const place = varautocomplete.getPlace()
          //console.log("place:",place)
          if (place.geometry) {
          //console.log("lat:",place.geometry.location.lat())
          setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
          }
        } else {
          //console.log('Autocomplete is not loaded yet!')
        }
      })
    return (
        <><div className="mb-5">
            <GoogleMap
                mapContainerStyle={props.mapContainerStyle}
                onLoad={handleLoad}
                onCenterChanged={handleCenterChanged}
                zoom={props.zoom}
                center={center}
                options={props.options}
                id="map2"
            >
                <Marker position={position} />
            </GoogleMap>
            <Autocomplete
            onLoad={handleOnLoad}
            onPlaceChanged={handleOnPlaceChanged}
          >
            <input
              type="text"
              placeholder="Suchen..."
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
          </Autocomplete>
          </div>
            {wid &&

                <Button variant="danger" onClick={handleAddLocation} >Position speichern
                </Button>}
                
            <Button onClick={getLocation}>Aktualisieren</Button>
        </>
    );
}