import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'

//https://github.com/devpato/react-NFC-sample

//https://github.com/devpato/react-NFC-sample

const ScanRFID = () => {

  const [message, setMessage] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const { actions, setActions } = useState('');

  const scan = useCallback(async () => {

    console.log("scan", "start", window)
    if ('NDEFReader' in window || true) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        console.log("Scan started successfully.");
        ndef.onreadingerror = () => {
          console.log("Cannot read data from the NFC tag. Try another one?");
        };

        ndef.onreading = event => {
          console.log("NDEF message read.");
          onReading(event);
        };

      } catch (error) {
        console.log(`Error! Scan failed to start: ${error}.`);
      };
    }
  });

  const onReading = ({ message, serialNumber }) => {
    setSerialNumber(serialNumber);
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          setMessage(textDecoder.decode(record.data));
          break;
        case "url":
          // TODO: Read URL record with record data.
          break;
        default:
        // TODO: Handle other records with record data.
      }
    }
  };

  useEffect(() => {
    scan();
  });

  return (
    <>
      
        <div>
          <p>Serial Number: {serialNumber}</p>
          <p>Message: {message}</p>
        </div>
        
    </>
  );
}

export default ScanRFID;